import { downloadFile, request, uploadFile } from "@/utils/request";
import { DATE_FORMAT } from "@/constants/common";
import moment from "moment";

export const HelpersMixin = {
  dateFormat: DATE_FORMAT,
  methods: {
    $formatDate(date) {
      return date ? moment(date).format(DATE_FORMAT) : "";
    },
    $getSearchParam(key = null) {
      const { query } = this.$route.query;
      if (!key) return query;
      return query[key] || null;
    },
    $setSearchParam(param, value = null) {
      const newQuery = { ...this.$route.query };
      const isQueryObject = typeof param === typeof {};
      if (!param || (isQueryObject && !Object.keys(param).length)) {
        return;
      }
      if (isQueryObject) {
        Object.entries(param).forEach(([filter, values]) => {
          const newValue = typeof values === typeof {} ? values?.value : values;
          if (!newValue) delete newQuery[filter];
          else newQuery[filter] = newValue;
        });
      } else {
        if (!value) delete newQuery[param];
        else newQuery[param] = value;
      }

      this.$router
        .replace({ query: newQuery, path: this.$route.path })
        .catch((err) => err);
    },
    $request(parameters) {
      return request(parameters);
    },
    $uploadFile(parameters) {
      return uploadFile(parameters);
    },
    $downloadFile(parameters) {
      return downloadFile(parameters);
    },
    $capitalize(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    },
  },
};
