import { parsePaginationHeaders } from "./parseHeaders";
import { ActiveUser } from "@/services/user";
import { FILES_ENDPOINTS } from "@/services/files";
import { i18n } from "@/main";

export const encodeQuery = (query) => {
  const searchParams = new URLSearchParams(query || {});
  return searchParams.toString();
};

export const redirectIfNotAuthenticated = (res) => {
  if (res.status === 401) {
    ActiveUser.clear();
    window.localStorage.setItem(
      "pathToLoadAfterLogin",
      window.location.pathname
    );
    return window.location.replace("/account/login");
  }
  if (res.status === 403) return window.location.replace("/");
};

export const request = async ({
  url,
  query,
  headers = {},
  method = "GET",
  body,
  formData,
  skipRedirect = false,
  ...rest
}) => {
  const methodLower = method.toLowerCase();
  const jsonMethods = ["post", "put", "patch", "delete"];
  if (body && jsonMethods.includes(methodLower) && !headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }
  if (body && typeof body === "object") {
    body = JSON.stringify(body);
  }
  if (formData) {
    body = formData;
  }
  const token = ActiveUser.getToken();
  if (token) {
    headers.Authorization = ActiveUser.getAuthorization();
  }
  if (query) {
    url = `${url}?${encodeQuery(query)}`;
  }
  const res = await fetch(url, {
    method,
    headers,
    body,
    ...rest,
  });

  if (!skipRedirect) {
    redirectIfNotAuthenticated(res);
  }

  const contentType = res.headers.get("content-type");
  if (contentType !== "application/json" || res.status === 204) {
    return {
      res,
      data: {},
      pagination: {},
      headers: res.headers,
    };
  }

  const data = await res.json();
  const pagination = parsePaginationHeaders(res.headers);

  return {
    res,
    data,
    pagination,
    headers: res.headers,
  };
};

export const getFileNameFromHeaders = (headers) => {
  return headers
    .get("content-disposition")
    ?.split("filename=")
    .pop()
    .replaceAll('"', "");
};

export const downloadFile = async ({
  name,
  fileId,
  downloadFileName = "",
  url = FILES_ENDPOINTS.download,
  headers = {},
  method = "GET",
  query = {},
}) => {
  query.name = name;
  query.file_id = fileId;
  url = `${url}?${encodeQuery(query)}`;

  headers.Authorization = ActiveUser.getAuthorization();
  headers["Content-Type"] = "application/json";
  headers["Cache-Control"] = "no-cache";

  const response = await fetch(url, {
    method,
    headers,
  });
  if (response.status !== 200) {
    return alert(i18n.global.t("File not found"));
  }
  downloadFileName =
    downloadFileName || getFileNameFromHeaders(response.headers);
  const fileResponse = await response.blob();
  const objectUrl = window.URL.createObjectURL(fileResponse);
  const anchor = document.createElement("a");

  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = downloadFileName;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
  anchor.remove();
};

export const uploadFile = ({
  fileId,
  file,
  onProgress,
  onLoad,
  onStateChange,
  fileName,
  query = {},
  url = FILES_ENDPOINTS.upload,
  method = "POST",
}) => {
  return new Promise((resolve) => {
    const resolveUpload = (message) => {
      if (typeof message === "string") {
        alert(message);
      } else {
        console.warn(i18n.global.t("Upload error"), message);
      }
      resolve();
    };

    query.file_id = fileId;
    url = `${url}?${encodeQuery(query)}`;

    const formData = new FormData();
    fileName = fileName || file.name;
    formData.append("file", file, fileName);
    formData.append("filename", fileName);

    // Files bigger then 80MB aren't supported with current nginx config
    if (file.size > 80000000) {
      return resolveUpload(i18n.global.t("File is too big"));
    }
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      onStateChange && onStateChange(xhr, file.name);
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (!xhr.status) {
          return;
        }
        onProgress && onProgress(100);
        if (xhr.status === 201) {
          return resolveUpload(i18n.global.t("File was uploaded successfully"));
        }
        redirectIfNotAuthenticated(xhr);
        const response = JSON.parse(xhr.responseText);
        if (response?.detail) {
          return resolveUpload(response.detail);
        }
        resolveUpload(i18n.global.t("Error occurred during upload"));
      }
    };
    xhr.upload.onload = onLoad;

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable && onProgress) {
        onProgress((e.loaded / e.total) * 100);
      }
    };
    onProgress && onProgress(0);

    xhr.open(method, url);
    xhr.setRequestHeader("authorization", ActiveUser.getAuthorization());
    xhr.send(formData);
  });
};