import { request } from '@/utils/request';
import { BASE_URL } from '@/constants/config';
import { ActiveUser } from '@/services/user';


export const USER_MANAGEMENT_ENDPOINTS = {
	check_gate: `${BASE_URL}api/otp_auth/login_gate`,
};

class UserManagement {
	async check_gate(code) {
		const { data, res } = await request({
			url: `${USER_MANAGEMENT_ENDPOINTS.check_gate}?code=${code}`,
			method: 'GET',
			skipRedirect: true,
		});

		return {
			data,
			res,
		};
	}
}

export const UserManagementService = new UserManagement();
