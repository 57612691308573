import 'core-js/stable'
import 'regenerator-runtime/runtime';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { AiAcademiaSquare } from "oh-vue-icons/icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMagnifyingGlass,faStamp, faChevronLeft,faCheck, faChevronRight,faTrash,faPlus,faBars,faXmark,faRightFromBracket,faThumbsUp as faFilledThumbsUp,faBookmark as faFilledBookMark } from '@fortawesome/free-solid-svg-icons'
import {faBookmark,faCircleUser,faSquareCheck,faPenToSquare,faThumbsUp}  from '@fortawesome/free-regular-svg-icons'

require('moment/locale/fr');

import { createApp } from 'vue';
import App from './App'
import router from './router'
import CoreuiVue from '@mz/coreui-vue'
import { createI18n } from 'vue-i18n'
import store from './store'
import VueSimpleAlert from "vue3-simple-alert";
import MzRd from 'mz-rd';
import Message from 'vue-m-message'
import 'vue-m-message/dist/style.css'
import {BASE_URL, SENTRY_ENV} from '@/constants/config';
import { HelpersMixin } from '@/mixins/helpers';
import { locales } from '@/locales';
// main.js / main.ts
import { CIcon } from '@coreui/icons-vue';

// import  DatePicker  from 'v-calendar/src/components/Calendar.vue';



const app = createApp(App);

// Use plugin defaults (optional)
// Use the components
// app.component('VCalendar', Calendar)
// app.component('VDatePicker', DatePicker)
app.use(CoreuiVue);
app.use(MzRd, {
  apiUrl: BASE_URL,
  tokenBearer: "Bearer",
  tableClassess: "mz-table",
  isRaTable: true,
});

app.use(Message);
Message.setDefault({
  duration: 5000,
  position: "bottom-left",
  zIndex: 100000000,
});


export const i18n = createI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages: locales,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

app.mixin(HelpersMixin);

window.alert = (message, additional = {}) => {
  store.commit("setAlert", {
    message,
    color: additional.color || "success",
  });
};

const btnClasses = `btn py-1 px-3`;
const alertConfig = {
  buttonsStyling: false,
  reverseButtons: true,
  width: "24em",
  confirmButtonText: i18n.global.t("Confirm"),
  denyButtonText: i18n.global.t("No"),
  cancelButtonText: i18n.global.t("Cancel"),
  customClass: {
    actions: 'justify-content-between',
    denyButton: `${btnClasses} btn-outline-danger`,
    cancelButton: `${btnClasses} dg-btn--cancel btn-outline-danger ml-2`,
    confirmButton: `${btnClasses} dg-btn--ok btn-outline-primary`,
  },
};
app.use(VueSimpleAlert, alertConfig);

window.confirm = async (message, config = {}) => {
  try {
    config = {
      ...config,
      customClass:
        config.showCancelButton === false
          ? { ...alertConfig.customClass, cancelButton: "d-none" }
          : alertConfig.customClass,
    };
    await VueSimpleAlert.confirm(message, "", "", config);
    return true;
  } catch {
    return false;
  }
};

app.config.errorHandler = (error, vm, info) => {
  console.error(error, vm, info);
}

if (process.env.VUE_APP_SENTRY_DSN && SENTRY_ENV !== "DEV")
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [BASE_URL],
      }),
    ],
    environment: SENTRY_ENV,
    tracesSampleRate: 1.0,
  });

  
   library.add([faBookmark,faCircleUser,faSquareCheck,faCheck,faFilledThumbsUp,faFilledBookMark,faPenToSquare,faXmark,faBars,faThumbsUp,faMagnifyingGlass,faRightFromBracket,faPlus,faStamp,faChevronLeft, faChevronRight,faTrash])
   const defaultDatePickerFormat = 'YYYY-MM-DD';
   
   
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store);
app.use(router);
app.use(ElementPlus)
app.component('CIcon', CIcon)
app.component('AiAcademiaSquare', AiAcademiaSquare)
app.use(i18n);
app.mount("#app");
