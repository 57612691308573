import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const USER_ENDPOINTS = {
  list: `${BASE_URL}api/users/`,
  managers: `${BASE_URL}api/users/managers/`,
  //register: `${BASE_URL}auth/register`,
  me: `${BASE_URL}users/me`,
  detail: (id) => `${BASE_URL}api/users/${id}/`,
  role_update:`${BASE_URL}api/users/role_update/`,
  //roles: `${BASE_URL}api/roles/`,
};

class Users {

  async getUsers(page = 1, count = 10, text, level, countries, services) {
    /* 
    test: string,
    level: [string],
    countries: [string],
    services: [string],
    */
    const { data } = await request({
      url: USER_ENDPOINTS.list,
      method: "POST",
      body: {
        page,
        count,
        text,
        level,
        countries,
        services,
      },
    });
    return data;
  }

  async getManagers(page = 1, count = 10, text, level, countries, services, offices, roles) {
    /* 
    test: string,
    level: [string],
    countries: [string],
    services: [string],
    offices: [string],
    roles: [number], [2, 3]
    */
    const { data } = await request({
      url: USER_ENDPOINTS.managers,
      method: "POST",
      body: {
        page,
        count,
        text,
        level,
        countries,
        services,
        offices,
        roles
      },
    });
    return data;
  }

  async changeRole(id, action) {
    /* 
      This will automatically switch user from manager to ambassador and vice versa.
      No need to provide any office destination id.

      id: The user id
      action: The action to be performed. Either 'ambassador' or 'manager'
    */
    const { data } = await request({
      url: USER_ENDPOINTS.role_update,
      method: "POST",
      body: {
        id,
        action
      },
    });
    return data;
  }

  async getActiveUserInfo() {
    const { data } = await request({
      url: USER_ENDPOINTS.me,
    });
    return data;
  }

  async getUser(id) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
    });
    return data;
  }

  /* async createUser(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.register,
      method: "POST",
      body: userData,
    });
    return data;
  }
 */

  /* async editUser(id, userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
      method: "PATCH",
      body: userData,
    });
    return data;
  } */

  /* async deleteUser(id) {
    return await request({
      url: USER_ENDPOINTS.detail(id),
      method: "DELETE",
    });
  } */

  /* async getRoles() {
    const { data } = await request({
      url: USER_ENDPOINTS.roles,
    });
    return data;
  } */
}

export const UsersService = new Users();
